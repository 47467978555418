import { Inject, Injectable } from '@angular/core';
import {BaseApiService} from './base-api.service';
import {Observable} from 'rxjs';
import {ApiModule} from './api.module';
import {HttpParams} from '@angular/common/http';
import semver from 'semver';
import { AWS_FIRST_VERSION, CORE_CONFIGURATION, SessionState } from '@myvf/core';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: ApiModule,
  deps: [BaseApiService]
})
export class CatalogApiService {
  constructor(
    private http: BaseApiService,
    private store: Store,
    @Inject(CORE_CONFIGURATION) private config
  ) {
  }

  /**
   * catalog api get active offers
   * @param msisdn current msisdn
   */
  activeOffers(msisdn: string): Observable<any> {
    const params = new HttpParams()
      .set('msisdn', msisdn);
    return this.http.get('/api/journeycatalog/v1/activeproducts', params);
  }

  /**
   * perform deactivation
   * @param msisdn current msisdn
   * @param productId current product id request
   */
  performDeactivation(msisdn: string, productId: string): Observable<any> {
    return this.http.delete(`/api/journeycatalog/v1/activeproducts/${productId}?msisdn=${msisdn}`);
  }

  /**
   * catalog api get activable offers by customer
   * @param sims items with type sim coming from session
   * @param landlines items with type landline coming from session
   * @param productType productType to filter in BE
   * @param hideError hide backend errors
   * @param noLoader hide global loading
   */
  offersByCustomer(sims: Array<string>, landlines: Array<string>, productType: string[], hideError: boolean = false, noLoader: boolean = false): Observable<any> {
    const {appVersion} = this.store.selectSnapshot(SessionState);
    let params = new HttpParams();
    if (productType?.length) {
      productType.forEach(v => params = params.append('productType', v) );
    }
    if (sims?.length) {
      sims.forEach(v => params = params.append('sims', v) );
    }
    if (landlines?.length) {
      landlines.forEach(v => params = params.append('landlines', v) );
    }
    if (noLoader) {
      params = params.set('noLoader', 'true');
    }

    return this.http.get(
      (semver.satisfies(appVersion, `< ${AWS_FIRST_VERSION}`) ?
        '/api/journeycatalog/v3/activableproducts' : `/${this.config.environment}/catalog/v1/retrieveActivableProducts`),
      params, 'json', hideError
    );
  }

  /**
   * catalog api retrieve customer products
   * @param customerId customer id
   * @param hideError hide backend errors
   * @param noLoader hide global loading
   */
  retrieveCustomerProducts(customerId: string, hideError: boolean = false, noLoader: boolean = false): Observable<any> {
    let params = new HttpParams()
      .set('customerId', customerId);
    if (noLoader) {
      params = params.set('noLoader', 'true');
    }
    return this.http.get(`/${this.config.environment}/catalog/v1/retrieveCustomerProducts`, params, 'json', hideError);
  }

  /**
   * catalog api perform an offer activation
   * @param msisdn current msisdn
   * @param productType current product type
   * @param skipCheck boolean value that perform BE check
   * @param body request body
   * @param noLoader boolean value for show/hide loader
   */
  performActivation(
    msisdn: string,
    productType: string,
    skipCheck: boolean,
    body: object,
    noLoader?: boolean
  ): Observable<any> {
    const {appVersion} = this.store.selectSnapshot(SessionState);
    let path = (semver.satisfies(appVersion, `< ${AWS_FIRST_VERSION}`) ?
      `/api/journeycatalog/v1/activableproducts?skipCheck=${skipCheck}&msisdn=${msisdn}&productType=${productType}` : `/${this.config.environment}/catalog/v1/activateProducts?skipCheck=${skipCheck}&item=${msisdn}&productType=${productType}`);

    if (typeof noLoader !== 'undefined') {
      path += '&noLoader=false';
    }

    return this.http.post(path, body, true);
  }

  /**
   * catalog api perform an push activation
   * @param msisdn current msisdn
   * @param body request body
   */
  pushActivation(
    msisdn: string,
    body: object
  ): Observable<any> {
    const {appVersion} = this.store.selectSnapshot(SessionState);

    return this.http.post(
      (semver.satisfies(appVersion, `< ${AWS_FIRST_VERSION}`) ?
        `/api/journeycatalog/v1/pushactivation?msisdn=${msisdn}` : `/${this.config.environment}/catalog/v1/activateProductsByPush?sim=${msisdn}`),
      body, true
    );
  }
}
