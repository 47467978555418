import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AuthGuard, CORE_CONFIGURATION, CoreOptions } from '@myvf/core';
import {
  AemCategoriesCatalogResolver,
  AemCategoriesNewsResolver,
  AemGuard,
  AemMappingResolver,
  AemMessagesResolver,
  AemDigitalContainerResolver,
  AemDigitalBenefitsResolver
} from '@myvf/aem';
import {environment} from '../environments/environment';
import { IsLegacyGuard } from './shared/IsLegacy.guard';

const outlets = ['primary', 'digitalContainer'];
const AuthOrAemGuardByOutlet: Routes = [];
const AuthAndAemGuardByOutlet: Routes = [];
const NoneGuardByOutlet: Routes = [];

// Auth Or Aem Guard By Outlet
outlets.forEach(outlet => {
  AuthOrAemGuardByOutlet.push(
    /////////
    // DigitalContainer //
    /////////
    {
      path: 'content/myvf-consumer/app/digital-container.html',
      canActivate: [AuthGuard],
      loadChildren: () => import('./digital-container/digital-container.module').then(m => m.DigitalContainerModule),
      outlet: outlet
    },
    //////////////
    // download //
    //////////////
    {
      path: 'content/myvf-consumer/app/download.html',
      canActivate: [AemGuard],
      loadChildren: () => import('./download/download.module').then(m => m.DownloadModule),
      outlet: outlet
    },
    /////////////////////
    // external anchor //
    /////////////////////
    {
      path: 'content/myvf-consumer/app/external-anchor.html',
      canActivate: [AemGuard],
      loadChildren: () => import('./external-anchor/external-anchor.module').then(m => m.ExternalAnchorModule),
      outlet: outlet
    }
  );
});

// Auth And Aem Guard By Outlet
outlets.forEach(outlet => {
  AuthAndAemGuardByOutlet.push(
    ////////////////////////////////////
    //    Digital Convergence         //
    ///////////////////////////////////
    {
      path: 'content/myvf-consumer/app/digital-convergence',
      loadChildren: () => import('./digital-convergence/digital-convergence.module').then(m => m.DigitalConvergenceModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    /////////////
    // Catalog //
    /////////////
    {
      path: 'content/myvf-consumer/app/catalog',
      loadChildren: () => import('./shared/next-reader.module').then(m => m.NextReaderModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    ///////////////
    // Onenumber //
    ///////////////
    {
      path: 'content/myvf-consumer/app/onenumber.html',
      loadChildren: () => import('./legacy-onenumber/onenumber.module').then(m => m.OnenumberModule),
      canActivate: [AuthGuard, IsLegacyGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    /////////////
    // Profile //
    /////////////
    {
      path: 'content/myvf-consumer/app/profile',
      loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },{
      path: 'content/myvf-consumer/app/profile.html',
      loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    //////////////
    // CONSENTS //
    //////////////
    {
      path: 'content/myvf-consumer/app/consents',
      loadChildren: () => import('./legacy-consents/consents.module').then(m => m.ConsentsModule),
      canActivate: [AuthGuard, IsLegacyGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    /////////
    // Puk //
    /////////
    {
      path: 'content/myvf-consumer/app/puk.html',
      loadChildren: () => import('./legacy-puk/puk.module').then(m => m.PukModule),
      canActivate: [AuthGuard, IsLegacyGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    //////////////
    // Invoices //
    //////////////
    {
      path: 'content/myvf-consumer/app/invoices',
      loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    {
      path: 'content/myvf-consumer/app/invoices.html',
      loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    ////////////////////
    // Top Up History //
    ////////////////////
    {
      path: 'content/myvf-consumer/app/topuphistory.html',
      loadChildren: () => import('./top-up-history/top-up-history.module').then(m => m.TopUpHistoryModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    ////////////////////
    // Costs //
    ////////////////////
    {
      path: 'content/myvf-consumer/app/costs',
      loadChildren: () => import('./costs/costs.module').then(m => m.CostsModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },{
      path: 'content/myvf-consumer/app/costs.html',
      loadChildren: () => import('./costs/costs.module').then(m => m.CostsModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    ////////////////////
    // Usage //
    ////////////////////
    {
      path: 'content/myvf-consumer/app/usage',
      loadChildren: () => import('./usage/usage.module').then(m => m.UsageModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },{
      path: 'content/myvf-consumer/app/usage.html',
      loadChildren: () => import('./usage/usage.module').then(m => m.UsageModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    /////////////////
    // Giga Family //
    /////////////////
    {
      path: 'content/myvf-consumer/app/giga-family',
      loadChildren: () => import('./legacy-giga-family/giga-family.module').then(m => m.GigaFamilyModule),
      canActivate: [AuthGuard, IsLegacyGuard], canActivateChild: [AemGuard], outlet: outlet
    },{
      path: 'content/myvf-consumer/app/giga-family.html',
      loadChildren: () => import('./legacy-giga-family/giga-family.module').then(m => m.GigaFamilyModule),
      canActivate: [AuthGuard, IsLegacyGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    //////////////////////
    // Automatic Top Up //
    //////////////////////
    {
      path: 'content/myvf-consumer/app/auto-top-up.html',
      loadChildren: () => import('./legacy-auto-top-up/auto-top-up.module').then(m => m.AutoTopUpModule),
      canActivate: [AuthGuard, IsLegacyGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    /////////////////////////
    // Go To External Page //
    /////////////////////////
    {
      path: 'content/myvf-consumer/app/external-redirector.html',
      loadChildren: () => import('./external-redirector/external-redirector.module').then(m => m.ExternalRedirectorModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    /////////////////////////
    // Go To External Page for JAKALA //
    /////////////////////////
    {
      path: 'content/myvf-consumer/app/external-redirector-jakala.html',
      loadChildren: () => import('./external-redirector-jakala/external-redirector-jakala.module').then(m => m.ExternalRedirectorJakalaModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    /////////
    // Generic Error Page //
    /////////
    {
      path: 'content/myvf-consumer/app/generic-error-page.html',
      loadChildren: () => import('./generic-error-page/generic-error-page.module').then(m => m.GenericErrorPageModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    ////////////////////
    //    Lineup    //
    ///////////////////
    {
      path: 'content/myvf-consumer/app/lineup.html',
      loadChildren: () => import('./legacy-lineup/lineup.module').then(m => m.LineupModule),
      canActivate: [AuthGuard, IsLegacyGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    ////////////////////
    //    Green Counter    //
    ///////////////////
    {
      path: 'content/myvf-consumer/app/green-counter.html',
      loadChildren: () => import('./green-counter/green-counter.module').then(m => m.GreenCounterModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    ////////////////////
    //    Vodafone News    //
    ///////////////////
    {
      path: 'content/myvf-consumer/app/vodafone-news',
      loadChildren: () => import('./vodafone-news/vodafone-news.module').then(m => m.VodafoneNewsModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    {
      path: 'content/myvf-consumer/app/vodafone-news.html',
      loadChildren: () => import('./vodafone-news/vodafone-news.module').then(m => m.VodafoneNewsModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    ////////////////////
    //    Next registration encrypt  //
    ///////////////////
    {
      path: 'content/myvf-consumer/app/registration-encrypt.html',
      loadChildren: () => import('./registration-encrypt/registration-encrypt.module').then(m => m.RegistrationEncryptModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    ////////////////////
    //    MOP         //
    ///////////////////
    {
      path: 'content/myvf-consumer/app/mop',
      loadChildren: () => import('./mop/mop.module').then(m => m.MopModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    ////////////////////
    //    FLEX         //
    ///////////////////
    {
      path: 'content/myvf-consumer/app/flex.html',
      loadChildren: () => import('./flex/flex.module').then(m => m.FlexModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    ////////////////////
    //  Omnitracking  //
    ///////////////////
    {
      path: 'content/myvf-consumer/app/omnitracking',
      loadChildren: () => import('./omnitracking/omnitracking.module').then(m => m.OmnitrackingModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    }, {
      path: 'content/myvf-consumer/app/omnitracking.html',
      loadChildren: () => import('./omnitracking/omnitracking.module').then(m => m.OmnitrackingModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    ////////////////////
    //    Recharge    //
    ////////////////////
    {
      path: 'content/myvf-consumer/app/recharge',
      loadChildren: () => import('./recharge/recharge.module').then(m => m.RechargeModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },{
      path: 'content/myvf-consumer/app/recharge.html',
      loadChildren: () => import('./recharge/recharge.module').then(m => m.RechargeModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
    ////////////////////////////
    // EmptyWebviewRedirector //
    ////////////////////////////
    {
      path: 'content/myvf-consumer/app/empty-webview-redirector',
      loadChildren: () => import('./empty-webview-redirector/empty-webview-redirector.module').then(m => m.EmptyWebviewRedirectorModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },{
      path: 'content/myvf-consumer/app/empty-webview-redirector.html',
      loadChildren: () => import('./empty-webview-redirector/empty-webview-redirector.module').then(m => m.EmptyWebviewRedirectorModule),
      canActivate: [AuthGuard], canActivateChild: [AemGuard], outlet: outlet
    },
  );
});

// NoneGuardByOutlet
outlets.forEach(outlet => {
  NoneGuardByOutlet.push(
    ///////////
    // Error //
    ///////////
    {
      path: 'content/myvf-consumer/app/error',
      loadChildren: () => import('./error/error.module').then(m => m.ErrorModule),
      outlet: outlet
    },{
      path: 'content/myvf-consumer/app/error.html',
      loadChildren: () => import('./error/error.module').then(m => m.ErrorModule),
      outlet: outlet
    },
    //////////////
    // Fallback //
    //////////////
    {
      path: '**',
      redirectTo: 'content/myvf-consumer/app/error/page-not-found.html',
      pathMatch: 'full',
      outlet: outlet
    }
  );
});

const routes: Routes = [
  ...AuthOrAemGuardByOutlet,
  ...AuthAndAemGuardByOutlet,
  ...NoneGuardByOutlet
];

const devRoutes = [{
  path: 'login',
  loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  outlet: 'primary'
}];

@NgModule({
  imports: [
    RouterModule.forRoot(
      environment.production ? routes : [...devRoutes, ...routes],
      {
        enableTracing: (CORE_CONFIGURATION as CoreOptions).enableTracing
      }
    )],
  exports: [RouterModule],
  providers: [AemMessagesResolver, AemMappingResolver, AemCategoriesNewsResolver, AemCategoriesCatalogResolver, AemDigitalContainerResolver, AemDigitalBenefitsResolver]
})
export class AppRoutingModule {
}
